<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-icon v-if="validBeverage" class="mdi-icon-panel pr-2" color="success"
        >mdi-format-list-checks</v-icon
      >
      <v-icon v-else class="mdi-icon-panel pr-2" color="primary"
        >mdi-alert-circle</v-icon
      >
      {{ $t('dailyMenu.beverage') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="daily-menu-product">
      <v-expand-transition>
        <div v-show="!validBeverage">
          <v-alert
            type="warning"
            border="left"
            class="my-4 mx-4"
            colored-border
            elevation="2"
            >{{ $t('dailyMenu.selectBeverage') }}</v-alert
          >
        </div>
      </v-expand-transition>

      <easy-serve-daily-menu-product
        v-for="product in beverages"
        :key="'beverage_' + product.id"
        :product="product"
        :selected="selectedBeverage"
        @select-product="selectProduct($event)"
      ></easy-serve-daily-menu-product>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import EasyServeDailyMenuProduct from './EasyServeDailyMenuProduct';

export default {
  props: {
    config: Object,
    beverages: Array,
  },

  components: { EasyServeDailyMenuProduct },

  computed: {
    ...mapGetters('dailymenuSelection', {
      selectedBeverage: 'selectedBeverage',
      validBeverage: 'validBeverage',
    }),
  },

  methods: {
    selectProduct(id) {
      this.$store.dispatch(
        'dailymenuSelection/selectBeverage',
        this.selectedBeverage == id ? 0 : id
      );
    },
  },
};
</script>

<style></style>
