<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-icon v-if="validDishes" class="mdi-icon-panel pr-2" color="success"
        >mdi-format-list-checks</v-icon
      >
      <v-icon v-else class="mdi-icon-panel pr-2" color="primary"
        >mdi-alert-circle</v-icon
      >
      {{ $t('dailyMenu.firstDish') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="daily-menu-product">
      <v-expand-transition>
        <div v-show="!validDishes">
          <v-alert
            type="warning"
            border="left"
            class="my-4 mx-4"
            colored-border
            elevation="2"
          >
            <span v-if="config.two_first_dishes">{{
              $t('dailyMenu.selectTwoFirstDishes')
            }}</span>
            <span v-else>{{ $t('dailyMenu.selectOneFirstDish') }}</span>
          </v-alert>
        </div>
      </v-expand-transition>

      <easy-serve-daily-menu-product
        v-for="product in firstDishes"
        :key="'first_dishes_' + product.id"
        :product="product"
        :selected="selectedFirstDishes"
        @select-product="selectProduct($event)"
      ></easy-serve-daily-menu-product>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import EasyServeDailyMenuProduct from './EasyServeDailyMenuProduct';

export default {
  props: {
    config: Object,
    firstDishes: Array,
  },

  components: { EasyServeDailyMenuProduct },

  computed: {
    ...mapGetters('dailymenuSelection', {
      selectedFirstDishes: 'selectedFirstDishes',
      validDishes: 'validDishes',
    }),
  },

  methods: {
    selectProduct(id) {
      let firstDishes = [];

      if (this.selectedFirstDishes.includes(id)) {
        firstDishes = this.selectedFirstDishes.filter(i => i != id);
      } else {
        firstDishes = this.selectedFirstDishes.slice(0);
        firstDishes.push(id);
      }

      this.$store.dispatch('dailymenuSelection/selectFirstDishes', firstDishes);
    },
  },
};
</script>

<style></style>
