<template>
  <!-- Product card -->
  <v-card flat tile ripple @click="$emit('select-product', product.id)">
    <v-card-text class="pt-2 px-6" :class="textClasses">
      <v-row
        no-gutters
        row
        class="align-center"
        :class="harmful ? '' : 'grey--text text--darken-3'"
      >
        <div class="body-1 pr-2">
          <v-icon
            v-if="isSelected"
            :color="harmful ? 'red darken-2' : 'primary'"
          >
            mdi-checkbox-marked
          </v-icon>

          <v-icon v-else :color="harmful ? 'red darken-2' : ''">
            mdi-checkbox-blank-outline
          </v-icon>
        </div>
        <v-col
          class="font-weight-medium subtitle-1 text-truncate"
          v-html="$options.filters.translate(product.name, $i18n)"
        ></v-col>
        <div
          class="subtitle-2 text-right"
          style="min-width: 50px"
          v-if="product.price"
        >
          + {{ product.price | currency_money }}
        </div>
      </v-row>

      <v-row no-gutters>
        <v-col
          cols="12"
          class="text-justify"
          v-html="$options.filters.translate(product.description, $i18n)"
        ></v-col>
      </v-row>

      <!-- <v-row no-gutters>
        <v-col cols="12"></v-col>
      </v-row>-->
    </v-card-text>

    <!-- Testing allergens. -->
    <v-card-text
      class="pt-0 pb-2 px-6 allergens"
      v-if="product.allergens.length > 0"
    >
      <v-avatar
        size="32"
        class="mr-1 mb-2 elevation-1"
        v-for="allergen in allergens"
        :key="'allergen_' + allergen.id"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <img
              :src="allergen.image_path"
              v-on="on"
              :alt="allergen.name[$i18n.locale]"
              :class="affectedBy(allergen) ? 'affected-by' : ''"
            />
          </template>
          <span>{{ allergen.name | translate($i18n) }}</span>
        </v-tooltip>
      </v-avatar>
    </v-card-text>
    <!-- Testing allergens. -->
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    product: Object,
    selected: {},
  },

  computed: {
    ...mapGetters('allergens', {
      allAllergens: 'allergens',
      selectedAllergens: 'selected',
    }),

    allergens() {
      // We need to load the full allergen object using the id's from the products
      return this.allAllergens.filter(allergen => {
        return !!this.product.allergens.find(a => a.id === allergen.id);
      });
    },

    harmful() {
      const found = this.allergens.find(allergen => {
        return this.affectedBy(allergen);
      });

      return found != undefined;
    },

    isSelected() {
      if (Array.isArray(this.selected)) {
        return this.selected.includes(this.product.id);
      }

      return this.selected == this.product.id;
    },

    textClasses() {
      // :class="harmful ? 'red--text text--darken-2' : ''"
      // product.allergens.length == 0 ? 'pb-2' : 'pb-0'
      let classes = [];
      if (this.product.allergens.length == 0) {
        classes.push('pb-2');
      } else {
        classes.push('pb-0');
      }

      if (this.harmful) {
        classes.push('red--text text--darken-2');
      }

      return classes;
    },
  },

  methods: {
    affectedBy(allergen) {
      return this.selectedAllergens.includes(allergen.id);
    },
  },
};
</script>

<style></style>
