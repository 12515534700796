<template>
  <v-list subheader dense class="elevation-2 mb-5">
    <v-subheader>{{ $t("dailyMenu.prices.title") }}</v-subheader>

    <v-list-item>
      <v-list-item-action class="mr-3" v-if="selectionType == ''">
        <v-icon>mdi-hand-pointing-right</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{
          $t("dailyMenu.prices.firstSecondDishes")
        }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <span class="subtitle-2">{{ dailyMenu.price | currency_money }}</span>
      </v-list-item-action>
    </v-list-item>

    <v-list-item v-if="dailyMenu.config.only_first_dishes">
      <v-list-item-action
        class="mr-3"
        v-if="selectionType == 'only_first_dishes'"
      >
        <v-icon>mdi-hand-pointing-right</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{
          $t("dailyMenu.prices.onlyFirstDishes")
        }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <span class="subtitle-2">{{
          dailyMenu.config.price_only_first_dishes | currency_money
        }}</span>
      </v-list-item-action>
    </v-list-item>

    <v-list-item v-if="dailyMenu.config.two_first_dishes">
      <v-list-item-action
        class="mr-3"
        v-if="selectionType == 'two_first_dishes'"
      >
        <v-icon>mdi-hand-pointing-right</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{
          $t("dailyMenu.prices.twoFirstDishes")
        }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <span class="subtitle-2">{{
          dailyMenu.config.price_two_first_dishes | currency_money
        }}</span>
      </v-list-item-action>
    </v-list-item>

    <v-list-item v-if="dailyMenu.config.only_second_dishes">
      <v-list-item-action
        class="mr-3"
        v-if="selectionType == 'only_second_dishes'"
      >
        <v-icon>mdi-hand-pointing-right</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{
          $t("dailyMenu.prices.onlySecondDishes")
        }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <span class="subtitle-2">{{
          dailyMenu.config.price_only_second_dishes | currency_money
        }}</span>
      </v-list-item-action>
    </v-list-item>

    <v-list-item v-if="dailyMenu.config.two_second_dishes">
      <v-list-item-action
        class="mr-3"
        v-if="selectionType == 'two_second_dishes'"
      >
        <v-icon>mdi-hand-pointing-right</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{
          $t("dailyMenu.prices.twoSecondDishes")
        }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <span class="subtitle-2">{{
          dailyMenu.config.price_two_second_dishes | currency_money
        }}</span>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    dailyMenu: {},
  },

  computed: {
    ...mapGetters("dailymenuSelection", {
      selectedFirstDishes: "selectedFirstDishes",
      selectedSecondDishes: "selectedSecondDishes",
    }),

    selectionType() {
      if (
        this.dailyMenu.config.two_second_dishes &&
        this.selectedSecondDishes.length == 2 &&
        this.selectedFirstDishes.length == 0
      ) {
        return "two_second_dishes";
      }

      if (
        this.dailyMenu.config.two_first_dishes &&
        this.selectedFirstDishes.length == 2 &&
        this.selectedSecondDishes.length == 0
      ) {
        return "two_first_dishes";
      }

      if (
        this.dailyMenu.config.only_second_dishes &&
        this.selectedSecondDishes.length == 1 &&
        this.selectedFirstDishes.length == 0
      ) {
        return "only_second_dishes";
      }

      if (
        this.dailyMenu.config.only_first_dishes &&
        this.selectedFirstDishes.length == 1 &&
        this.selectedSecondDishes.length == 0
      ) {
        return "only_first_dishes";
      }

      // Normal menu by default
      return "";
    },
  },
};
</script>

<style></style>
