<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-icon v-if="validDessert" class="mdi-icon-panel pr-2" color="success"
        >mdi-format-list-checks</v-icon
      >
      <v-icon v-else class="mdi-icon-panel pr-2" color="primary"
        >mdi-alert-circle</v-icon
      >
      {{ $t('dailyMenu.dessert') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="daily-menu-product">
      <v-expand-transition>
        <div v-show="!validDessert">
          <v-alert
            type="warning"
            border="left"
            class="my-4 mx-4"
            colored-border
            elevation="2"
          >
            <span v-if="config.coffee_dessert != true && !validDessert">{{
              $t('dailyMenu.selectDessertOrCoffee')
            }}</span>
            <span v-if="config.coffee_dessert == true && !validDessert">{{
              $t('dailyMenu.selectDessertAndCoffee')
            }}</span>
          </v-alert>
        </div>
      </v-expand-transition>

      <easy-serve-daily-menu-product
        v-for="product in desserts"
        :key="'dessert_' + product.id"
        :product="product"
        :selected="selectedDessert"
        @select-product="selectProduct($event)"
      ></easy-serve-daily-menu-product>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import EasyServeDailyMenuProduct from './EasyServeDailyMenuProduct';

export default {
  props: {
    config: Object,
    desserts: Array,
  },

  components: { EasyServeDailyMenuProduct },

  computed: {
    ...mapGetters('dailymenuSelection', {
      selectedDessert: 'selectedDessert',
      validDessert: 'validDessert',
    }),
  },

  methods: {
    selectProduct(id) {
      this.$store.dispatch(
        'dailymenuSelection/selectDessert',
        this.selectedDessert == id ? 0 : id
      );
    },
  },
};
</script>

<style></style>
