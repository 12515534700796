<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-icon v-if="validDishes" class="mdi-icon-panel pr-2" color="success"
        >mdi-format-list-checks</v-icon
      >
      <v-icon v-else class="mdi-icon-panel pr-2" color="primary"
        >mdi-alert-circle</v-icon
      >
      {{ $t('dailyMenu.secondDish') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="daily-menu-product">
      <v-expand-transition>
        <div v-show="!validDishes">
          <v-alert
            type="warning"
            border="left"
            class="my-4 mx-4"
            colored-border
            elevation="2"
          >
            <span v-if="config.two_second_dishes">{{
              $t('dailyMenu.selectTwoSecondDishes')
            }}</span>
            <span v-else>{{ $t('dailyMenu.selectOneSecondDish') }}</span>
          </v-alert>
        </div>
      </v-expand-transition>

      <easy-serve-daily-menu-product
        v-for="product in secondDishes"
        :key="'second_dishes_' + product.id"
        :product="product"
        :selected="selectedSecondDishes"
        @select-product="selectProduct($event)"
      ></easy-serve-daily-menu-product>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import EasyServeDailyMenuProduct from './EasyServeDailyMenuProduct';

export default {
  props: {
    config: Object,
    secondDishes: Array,
  },

  components: { EasyServeDailyMenuProduct },

  computed: {
    ...mapGetters('dailymenuSelection', {
      selectedSecondDishes: 'selectedSecondDishes',
      validDishes: 'validDishes',
    }),
  },

  methods: {
    selectProduct(id) {
      let secondDishes = [];

      if (this.selectedSecondDishes.includes(id)) {
        secondDishes = this.selectedSecondDishes.filter(i => i != id);
      } else {
        secondDishes = this.selectedSecondDishes.slice(0);
        secondDishes.push(id);
      }

      this.$store.dispatch(
        'dailymenuSelection/selectSecondDishes',
        secondDishes
      );
    },
  },
};
</script>

<style></style>
