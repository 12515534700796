<template>
  <div id="allergens">
    <v-app-bar app color="white">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t('dailyMenu.title') }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <span class="subtitle-2">{{ price | currency_money }}</span>
    </v-app-bar>

    <v-main>
      <v-container fluid v-if="daily_menu">
        <!-- Menus de dia -->
        <h3
          class="headline mb-1"
          v-html="$options.filters.translate(daily_menu.name, $i18n)"
        ></h3>
        <div
          class="mb-4 body-2 grey--text text--darken-1"
          v-html="$options.filters.translate(daily_menu.description, $i18n)"
        ></div>

        <!-- Daily menu prices -->
        <easy-serve-daily-menu-prices
          :daily-menu="daily_menu"
        ></easy-serve-daily-menu-prices>
        <!-- Daily menu prices -->

        <!-- Daily menu products -->
        <v-expansion-panels accordion>
          <easy-serve-daily-menu-beverages
            :beverages="beverages"
            :config="daily_menu.config"
          ></easy-serve-daily-menu-beverages>

          <easy-serve-daily-menu-first-dishes
            :first-dishes="first_dishes"
            :config="daily_menu.config"
          ></easy-serve-daily-menu-first-dishes>

          <easy-serve-daily-menu-second-dishes
            :second-dishes="second_dishes"
            :config="daily_menu.config"
          ></easy-serve-daily-menu-second-dishes>

          <easy-serve-daily-menu-desserts
            :desserts="desserts"
            :config="daily_menu.config"
          ></easy-serve-daily-menu-desserts>

          <easy-serve-daily-menu-coffees
            :coffees="coffees"
            :config="daily_menu.config"
          ></easy-serve-daily-menu-coffees>
        </v-expansion-panels>
        <!-- Daily menu products -->

        <div class="mt-5">
          <v-btn
            block
            color="primary"
            :disabled="!enableButton"
            @click="addToCart"
            >{{ $t('dailyMenu.addToCart') }}</v-btn
          >
        </div>

        <div v-if="daily_menu.notes">
          <!-- Notes of the daily menu -->
          <h3 class="subtitle-1 mt-5">{{ $t('dailyMenu.notes') }}</h3>
          <div
            class="mb-4 body-2 grey--text text--darken-1"
            v-html="$options.filters.translate(daily_menu.notes, $i18n)"
          ></div>
        </div>

        <easy-serve-about-us></easy-serve-about-us>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import EasyServeDailyMenuPrices from './dailymenu/EasyServeDailyMenuPrices';
import EasyServeDailyMenuBeverages from './dailymenu/EasyServeDailyMenuBeverages';
import EasyServeDailyMenuFirstDishes from './dailymenu/EasyServeDailyMenuFirstDishes';
import EasyServeDailyMenuSecondDishes from './dailymenu/EasyServeDailyMenuSecondDishes';
import EasyServeDailyMenuDesserts from './dailymenu/EasyServeDailyMenuDesserts';
import EasyServeDailyMenuCoffees from './dailymenu/EasyServeDailyMenuCoffees';

import EasyServeAboutUs from '../components/EasyServeAboutUs';

export default {
  components: {
    EasyServeDailyMenuPrices,
    EasyServeDailyMenuBeverages,
    EasyServeDailyMenuFirstDishes,
    EasyServeDailyMenuSecondDishes,
    EasyServeDailyMenuDesserts,
    EasyServeDailyMenuCoffees,
    EasyServeAboutUs,
  },

  data: () => ({}),

  computed: {
    ...mapGetters('restaurant', {
      restaurant: 'restaurant',
    }),

    ...mapGetters('dailymenus', {
      daily_menu: 'daily_menu',
      products: 'products',
      config: 'config',

      beverages: 'beverages',
      first_dishes: 'first_dishes',
      second_dishes: 'second_dishes',
      desserts: 'desserts',
      coffees: 'coffees',
    }),

    ...mapGetters('dailymenuSelection', {
      selectedBeverage: 'selectedBeverage',
      selectedFirstDishes: 'selectedFirstDishes',
      selectedSecondDishes: 'selectedSecondDishes',
      selectedDessert: 'selectedDessert',
      selectedCoffee: 'selectedCoffee',

      validBeverage: 'validBeverage',
      validDishes: 'validDishes',
      validDessert: 'validDessert',
      validCoffee: 'validCoffee',
    }),

    enableButton() {
      return (
        this.validBeverage &&
        this.validDishes &&
        this.validDessert &&
        this.validCoffee
      );
    },

    price() {
      if (!this.daily_menu) return 0;

      let totalPrice = this.daily_menu.price;

      if (
        this.daily_menu.config.two_first_dishes &&
        this.selectedFirstDishes.length == 2 &&
        this.selectedSecondDishes.length == 0
      ) {
        totalPrice = this.daily_menu.config.price_two_first_dishes;
      } else if (
        this.daily_menu.config.only_first_dishes &&
        this.selectedFirstDishes.length == 1 &&
        this.selectedSecondDishes.length == 0
      ) {
        totalPrice = this.daily_menu.config.price_only_first_dishes;
      } else if (
        this.daily_menu.config.two_second_dishes &&
        this.selectedFirstDishes.length == 0 &&
        this.selectedSecondDishes.length == 2
      ) {
        totalPrice = this.daily_menu.config.price_two_second_dishes;
      } else if (
        this.daily_menu.config.only_second_dishes &&
        this.selectedFirstDishes.length == 0 &&
        this.selectedSecondDishes.length == 1
      ) {
        totalPrice = this.daily_menu.config.price_only_second_dishes;
      }

      if (this.validBeverage) {
        let beverage = this.products.find(p => p.id == this.selectedBeverage);
        if (beverage != null && beverage.price != null)
          totalPrice += beverage.price;
      }

      if (this.validDessert) {
        let dessert = this.products.find(p => p.id == this.selectedDessert);
        if (dessert != null && dessert.price != null)
          totalPrice += dessert.price;
      }

      if (this.validCoffee) {
        let coffee = this.products.find(p => p.id == this.selectedCoffee);
        if (coffee != null && coffee.price != null) totalPrice += coffee.price;
      }

      if (this.validDishes) {
        let first_dishes = this.getFirstDishes();
        let second_dishes = this.getSecondDishes();

        first_dishes.forEach(p => {
          if (p.price != null) totalPrice += p.price;
        });

        second_dishes.forEach(p => {
          if (p.price != null) totalPrice += p.price;
        });
      }

      return totalPrice;
    },

    diferentPrices() {
      // Indicates if the current daily menu has many prices
      return (
        this.config.first_dishes_only ||
        this.config.two_first_dishes ||
        this.config.second_dishes_only ||
        this.config.two_second_dishes
      );
    },
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      // If the current daily menu has another ID than the requested by the parameter
      if (
        !this.daily_menu ||
        this.daily_menu.id != this.$route.params.daily_menu
      ) {
        // Clear
        this.$store.dispatch('dailymenuSelection/clear');
      }

      // Load the current daily menu
      this.$store
        .dispatch('dailymenus/load', this.$route.params.daily_menu)
        .then(results => {
          this.$store.dispatch(
            'dailymenuSelection/setDailyMenu',
            results.data.daily_menu
          );
          // console.log('Ok daily menu loaded');
        });
      // .catch(error => {
      //    console.error(error.response);
      // });
    },

    addToCart() {
      if (!this.enableButton) {
        // We have a problem ... someone is trying to do this by hand
        return;
      }

      let payload = {
        price: this.getSelectionPrice(),
        dailyMenu: this.daily_menu,
        beverage: this.products.find(e => e.id == this.selectedBeverage),
        dessert: this.products.find(e => e.id == this.selectedDessert),
        coffee: this.products.find(e => e.id == this.selectedCoffee),

        first_dishes: this.getFirstDishes(),
        second_dishes: this.getSecondDishes(),

        // Calculate total price
        total: this.price,
      };

      // Save the menu
      this.$store.dispatch('cart/addDailyMenu', payload);

      // Show the toast

      // Clear all data in the selection
      this.$store.dispatch('dailymenuSelection/clear');

      // Return to the menu
      this.$router.push({ name: 'menu' });
    },

    getSelectionPrice() {
      if (
        this.daily_menu.config.two_first_dishes &&
        this.selectedFirstDishes.length == 2 &&
        this.selectedSecondDishes.length == 0
      ) {
        return this.daily_menu.config.price_two_first_dishes;
      } else if (
        this.daily_menu.config.only_first_dishes &&
        this.selectedFirstDishes.length == 1 &&
        this.selectedSecondDishes.length == 0
      ) {
        return this.daily_menu.config.price_only_first_dishes;
      } else if (
        this.daily_menu.config.two_second_dishes &&
        this.selectedFirstDishes.length == 0 &&
        this.selectedSecondDishes.length == 2
      ) {
        return this.daily_menu.config.price_two_second_dishes;
      } else if (
        this.daily_menu.config.only_second_dishes &&
        this.selectedFirstDishes.length == 0 &&
        this.selectedSecondDishes.length == 1
      ) {
        return this.daily_menu.config.price_only_second_dishes;
      }

      return this.daily_menu.price;
    },

    getFirstDishes() {
      if (this.selectedFirstDishes.length == 0) {
        return [];
      }

      if (this.selectedFirstDishes.length == 1) {
        return [this.products.find(e => e.id == this.selectedFirstDishes[0])];
      }

      return [
        this.products.find(e => e.id == this.selectedFirstDishes[0]),
        this.products.find(e => e.id == this.selectedFirstDishes[1]),
      ];
    },

    getSecondDishes() {
      if (this.selectedSecondDishes.length == 0) {
        return [];
      }

      if (this.selectedSecondDishes.length == 1) {
        return [this.products.find(e => e.id == this.selectedSecondDishes[0])];
      }

      return [
        this.products.find(e => e.id == this.selectedSecondDishes[0]),
        this.products.find(e => e.id == this.selectedSecondDishes[1]),
      ];
    },
  },
};
</script>

<style>
.v-expansion-panel-header i.mdi-icon-panel {
  flex: none;
}

.daily-menu-product > .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
