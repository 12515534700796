<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-icon v-if="validCoffee" class="mdi-icon-panel pr-2" color="success"
        >mdi-format-list-checks</v-icon
      >
      <v-icon v-else class="mdi-icon-panel pr-2" color="primary">
        mdi-alert-circle
      </v-icon>
      {{ $t('dailyMenu.coffeeTee') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="daily-menu-product">
      <v-expand-transition>
        <div v-show="!validCoffee">
          <v-alert
            type="warning"
            border="left"
            class="my-4 mx-4"
            colored-border
            elevation="2"
          >
            <span v-if="config.coffee_dessert != true && !validCoffee">{{
              $t('dailyMenu.selectCoffeeOrDessert')
            }}</span>
            <span v-if="config.coffee_dessert == true && !validCoffee">{{
              $t('dailyMenu.selectCoffeeAndDessert')
            }}</span>
          </v-alert>
        </div>
      </v-expand-transition>

      <easy-serve-daily-menu-product
        v-for="product in coffees"
        :key="'coffee_' + product.id"
        :product="product"
        :selected="selectedCoffee"
        @select-product="selectProduct($event)"
      ></easy-serve-daily-menu-product>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import EasyServeDailyMenuProduct from './EasyServeDailyMenuProduct';

export default {
  props: {
    config: Object,
    coffees: Array,
  },

  components: { EasyServeDailyMenuProduct },

  computed: {
    ...mapGetters('dailymenuSelection', {
      selectedCoffee: 'selectedCoffee',
      validCoffee: 'validCoffee',
    }),
  },

  methods: {
    selectProduct(id) {
      this.$store.dispatch(
        'dailymenuSelection/selectCoffee',
        this.selectedCoffee == id ? 0 : id
      );
    },
  },
};
</script>

<style></style>
